@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

@layer base {
  .clickable {
    @apply hover:opacity-70 cursor-pointer select-none;
  }

  .clickable:active {
    @apply opacity-30;
  }

  /* Apply top margin to all button rows */
  .form-input-button {
    margin-top: 3rem;
  }

  /* ... and undo top margin for all button rows except first one */
  .form-input-button ~ .form-input-button {
      margin-bottom: 0;
  }

  .form-input-button + .w-full {
    margin-top:  2rem;
  }

  .form-input-button + .form-input-regular-upload {
    margin-top:  1rem;
  }

  .form-input-regular + .form-input-button{
    margin-top: 3rem;
  }

  .form-input-regular ~ .form-input-regular-select-buttons{
    margin-top: 2rem;
  }

  .form-input-regular + .form-check{
    margin-top: 1rem;
  }

  .form-input-regular-select + .form-input-regular-select{
    margin-top: 0;
  }  

  .form-input-regular-select + .form-input-regular-select-buttons{
    margin-top: 2rem;
  }

  .form-input-regular-select + .form-input-regular-upload {
    margin-top: 2rem;
  }

  .form-input-regular-select-buttons + .form-input-regular-select {
    margin-top: 1rem;
  }

  .form-input-button + .form-input-regular-select {
    margin-top: 2rem;
  }

  .form-input-regular-text + .form-input-regular-info {
    margin-top:  0.5rem;
  }

  .form-input-regular-info + .w-full {
    margin-top: 1rem;
  }

  .form-input-regular-select + .form-input-regular-info {
    margin-bottom: 0rem;
  }

  .form-input-regular-upload + .w-full {
    margin-top: 2rem;
  }

  .form-check + .form-input-button {
    margin-top: 1rem;
  }

  .form-check + .form-input-regular-select-buttons {
    margin-top:  1.5rem;
  }

  .form-input-regular-select-buttons + .form-input-regular-info {
    margin-bottom: 0rem;
  }

  .form-input-regular-select-buttons + div.form-input-regular-info {
    margin-bottom: 0rem;
  }

  .form-input-regular-select-buttons + .w-full {
    margin-bottom: 1rem;
  }

  .form-input-regular-text + .form-input-regular-select-buttons {
    margin-bottom:  1rem;
  }

  .form-input-regular-select-buttons + .form-input-regular-select {
    margin-bottom: 0;
  }

  html {
    @apply text-font;
  }

  body {
    @apply p-0 m-0;
    -webkit-font-smoothing:  antialiased !important;
    -moz-osx-font-smoothing:  grayscale !important;
  }

  .stretch-children {
    @apply flex space-x-1;
  }

  .stretch-children>* {
    @apply flex-1;
  }
}

@layer components {
  .n--breadcrumbs>a:last-of-type {
    color: rgb(33, 37, 41);
    font-weight: 500;
  }

  .n--form-field-list {
    min-height: calc(100vh - 190px);
    padding-bottom: 4rem;
  }

  .prose p {
    @apply text-lg;
  }
}